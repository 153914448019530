exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-class-assassin-tsx": () => import("./../../../src/pages/class/assassin.tsx" /* webpackChunkName: "component---src-pages-class-assassin-tsx" */),
  "component---src-pages-class-dervish-tsx": () => import("./../../../src/pages/class/dervish.tsx" /* webpackChunkName: "component---src-pages-class-dervish-tsx" */),
  "component---src-pages-class-elementalist-tsx": () => import("./../../../src/pages/class/elementalist.tsx" /* webpackChunkName: "component---src-pages-class-elementalist-tsx" */),
  "component---src-pages-class-mesmer-tsx": () => import("./../../../src/pages/class/mesmer.tsx" /* webpackChunkName: "component---src-pages-class-mesmer-tsx" */),
  "component---src-pages-class-monk-tsx": () => import("./../../../src/pages/class/monk.tsx" /* webpackChunkName: "component---src-pages-class-monk-tsx" */),
  "component---src-pages-class-necromancer-tsx": () => import("./../../../src/pages/class/necromancer.tsx" /* webpackChunkName: "component---src-pages-class-necromancer-tsx" */),
  "component---src-pages-class-paragon-tsx": () => import("./../../../src/pages/class/paragon.tsx" /* webpackChunkName: "component---src-pages-class-paragon-tsx" */),
  "component---src-pages-class-ranger-tsx": () => import("./../../../src/pages/class/ranger.tsx" /* webpackChunkName: "component---src-pages-class-ranger-tsx" */),
  "component---src-pages-class-ritualist-tsx": () => import("./../../../src/pages/class/ritualist.tsx" /* webpackChunkName: "component---src-pages-class-ritualist-tsx" */),
  "component---src-pages-class-warrior-tsx": () => import("./../../../src/pages/class/warrior.tsx" /* webpackChunkName: "component---src-pages-class-warrior-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

